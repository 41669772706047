@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles and resets */
*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

:before,
:after {
  --tw-content: "";
}

/* Root element styles */
:root {
  font-family: Soehne Buch, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

/* Font declarations */
@font-face {
  font-family: 'Soehne Buch';
  src: url(https://cdn.openai.com/common/fonts/soehne/soehne-buch.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Soehne Halbfett';
  src: url(https://cdn.openai.com/common/fonts/soehne/soehne-halbfett.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Soehne Mono Kraftig';
  src: url(https://cdn.openai.com/common/fonts/soehne/soehne-mono-kraftig.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Layout components */
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
}

.hero-container {
  position: relative;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
  padding: 2rem;
}

.hero-section {
  text-align: center;
  padding: 100px 20px;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Soehne Halbfett, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.hero-title {
  font-size: 48px;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
}

.hero-subtitle {
  font-size: 24px;
  margin-top: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}

/* Background and overlay elements */
.background {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.orb-button-container {
  position: relative;
  height: 100%;
  width: 100%;
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

/* Form elements */
.form,
.otherElements {
  position: relative;
  z-index: 2;
}

/* Root element styles */
html {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  line-height: inherit;
}

#root {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/* Utilities */
.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.m-4 {
  margin: 1rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-24 {
  height: 6rem;
}

.h-48 {
  height: 12rem;
}

.h-6 {
  height: 1.5rem;
}

.h-9 {
  height: 2.25rem;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-10 {
  width: 2.5rem;
}

.w-24 {
  width: 6rem;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-auto {
  overflow: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(230 184 92 / var(--tw-border-opacity));
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(18 20 24 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(230 184 92 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.leading-8 {
  line-height: 2rem;
}

.text-dark-primary {
  --tw-text-opacity: 1;
  color: rgb(22 35 37 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(230 184 92 / var(--tw-text-opacity));
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Media Queries - Mobile First Approach */
@media (min-width: 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:gap-24 {
    gap: 6rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:bg-transparent {
    background-color: transparent;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 768px) {
  .md\:h-36 {
    height: 9rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:gap-32 {
    gap: 8rem;
  }
}

@media (min-width: 1280px) {
  .xl\:gap-48 {
    gap: 12rem;
  }
}
